@use 'sass:map';
@use '@angular/material' as mat;
@import '../material-utils';

@mixin color($theme) {
	$color-config: mat.m2-get-color-config($theme);
	$primary-palette: map.get($color-config, 'primary');
	$highlight-color: mat.m2-get-color-from-palette($primary-palette, 400);
	$highlight-color-hover: mat.m2-get-color-from-palette($primary-palette, 200);

	.mat-button-toggle-group.mat-button-toggle-group {
		// Legacy
		--mat-legacy-button-toggle-text-color: var(--vis-color-text-default);
		--mat-legacy-button-toggle-state-layer-color: transparent;
		--mat-legacy-button-toggle-selected-state-text-color: var(--vis-color-text-on-dark-bg);
		--mat-legacy-button-toggle-selected-state-background-color: var(
			--vis-color-interactive-element-bg-primary-enabled
		);
		--mat-legacy-button-toggle-disabled-state-text-color: var(--vis-color-text-default);
		--mat-legacy-button-toggle-disabled-state-background-color: var(
			--vis-color-interactive-element-bg-default-enabled
		);
		--mat-legacy-button-toggle-disabled-selected-state-background-color: var(
			--vis-color-interactive-element-bg-primary-enabled
		);
		--mat-legacy-button-toggle-height: calc(var(--vis-gu) * 4);
		--mat-legacy-button-toggle-shape: 3px;
		--mat-legacy-button-toggle-hover-state-layer-opacity: 1;
		--mat-legacy-button-toggle-focus-state-layer-opacity: 1;
		// Standard
		--mat-standard-button-toggle-text-color: var(--vis-color-text-default);
		--mat-standard-button-toggle-background-color: var(
			--vis-color-interactive-element-bg-default-enabled
		);
		--mat-standard-button-toggle-state-layer-color: transparent;
		--mat-standard-button-toggle-selected-state-background-color: var(
			--vis-color-interactive-element-bg-activated-enabled
		);
		--mat-standard-button-toggle-selected-state-text-color: var(--vis-color-text-on-dark-bg);
		--mat-standard-button-toggle-disabled-state-text-color: var(--vis-color-text-default);
		--mat-standard-button-toggle-disabled-state-background-color: var(
			--vis-color-interactive-element-bg-default-enabled
		);
		--mat-standard-button-toggle-disabled-selected-state-text-color: var(
			--vis-color-text-on-dark-bg
		);
		--mat-standard-button-toggle-disabled-selected-state-background-color: var(
			--vis-color-interactive-element-bg-activated-enabled
		);
		--mat-standard-button-toggle-divider-color: transparent;
		--mat-standard-button-toggle-shape: calc(var(--vis-gu) / 2);
		--mat-standard-button-toggle-hover-state-layer-opacity: 1;
		--mat-standard-button-toggle-focus-state-layer-opacity: 1;
		--mat-standard-button-toggle-height: calc(var(--vis-gu) * 4);

		background: var(--mat-standard-button-toggle-background-color);
		border: none;
		box-shadow: none !important;

		&::before {
			content: '';
			border-radius: var(--vis-border-radius-default);
			border: 1px solid var(--vis-border-color);
			position: absolute;
			width: 100%;
			height: 100%;
			pointer-events: none;
			z-index: 10;
			box-sizing: border-box;
		}

		.mat-button-toggle {
			border: 1px solid var(--vis-border-color);
			position: relative;
			z-index: 2;
			overflow: hidden;
			box-sizing: border-box;

			&:not(:first-child) {
				border-left-color: transparent;
			}

			&:not(:last-child) {
				border-right-color: transparent;
			}

			&:not(.mat-button-toggle-disabled):not(.mat-button-toggle-checked):hover {
				background-color: var(--vis-color-interactive-element-bg-default-hovered);
				border-color: var(--vis-border-color);
				border-radius: var(--mat-legacy-button-toggle-shape);
				border: 1px solid var(--vis-border-color);
			}

			&:not(.mat-button-toggle-disabled):focus {
				background-color: var(--vis-color-interactive-element-bg-default-pressed);
			}

			&.mat-button-toggle-disabled {
				opacity: var(--vis-disabled-opacity);
			}

			&.mat-button-toggle-checked {
				border: 1px solid var(--vis-border-color-hover);
				border-radius: var(--mat-legacy-button-toggle-shape);
				z-index: 11;
			}
		}

		.mat-button-toggle-ripple,
		.mat-button-toggle-focus-overlay {
			display: none;
		}

		.mat-button-toggle-label-content {
			padding: 0 calc(var(--vis-gu) * 2);
		}

		&[ng-reflect-appearance='legacy'] {
			.mat-button-toggle.mat-button-toggle-checked {
				border-color: var(--vis-color-interactive-element-thin-line-primary-enabled);
			}
		}

		&[vis-stretch] {
			width: 100%;

			.mat-button-toggle {
				flex-grow: 1;
			}
		}

		.mat-pseudo-checkbox {
			display: none;
		}
	}

	.mat-icon:not([size]) {
		font-size: var(--vis-size-icon-medium);
		line-height: var(--vis-size-icon-medium);
		height: var(--vis-size-icon-medium);
		width: var(--vis-size-icon-medium);
	}

	.mat-button-toggle-label-content > vis-icon:first-child:last-child {
		vertical-align: sub;
	}
}

@mixin typography($theme) {
	button.mat-button-toggle,
	.mat-button-toggle-button {
		font: var(--vis-typography-body);
	}

	.mat-button-toggle-checked .mat-button-toggle-label-content {
		font-weight: 600;
	}
}

@mixin theme($theme) {
	$color-config: mat.m2-get-color-config($theme);

	@if $color-config != null {
		@include color($theme);
	}

	$typography-config: mat.m2-get-typography-config($theme);

	@if $typography-config != null {
		@include typography($theme);
	}
}
